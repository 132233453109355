import { doGet, doPost, doPut, doDelete } from "Requests/requests";

/**
 * Fetches tags. If companyId is provided, fetch tags for that company.
 * Otherwise, fetch all tags (fmtv user scenario).
 * @param {number|null} companyId
 * @returns {Promise<Object[]>}
 */
export const getTags = async (companyId = null) => {
  try {
    const params = {
      offset: 0,
      limit: 100,
      ...(companyId && { company_id: companyId })
    };
    return await doGet(`/company-tag`, { params });
  } catch (e) {
    throw e;
  }
};

/**
 * Creates a new tag.
 * @param {Object} data { tag_name, tag_description, status, company_id }
 * @returns {Promise<Object>}
 */
export const createTag = async (data) => {
  try {
    return await doPost(`/company-tag`, data);
  } catch (e) {
    throw e;
  }
};

/**
 * Updates an existing tag.
 * @param {number} id
 * @param {Object} data { tag_name, tag_description, status, company_id }
 * @returns {Promise<Object>}
 */
export const updateTag = async (id, data) => {
  const { tag_name, ...rest } = data;
  return await doPut(`/company-tag/${id}`, rest, { params: { tag_name } });
};

/**
 * Deletes a tag by its ID.
 * @param {number} id
 * @returns {Promise<Object>}
 */
export const deleteTag = async (id) => {
  try {
    return await doDelete(`/company-tag/${id}`);
  } catch (e) {
    throw e;
  }
};
