import React, { useEffect } from "react";
import { Drawer, Form, Input, Select, Button, Row, Col, notification } from "antd";
import { DrawerTitle, MainTitle, CloseBtnWrapper, DrawerBodyPadding } from "Styles/global";
import CloseBtnIcon from "Assets/Icons/CloseBtn";
import { createTag, updateTag } from "Requests/companies-tags";

const { TextArea } = Input;

const AddTagDrawer = ({ visible, onClose, tagData, handleAddOrUpdateTag, companyOptions, companyId }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (tagData) {
      form.setFieldsValue({
        tag_name: tagData.tag_name,
        tag_description: tagData.tag_description,
        status: tagData.status,
        company_id: tagData.company_id || companyId || null
      });
    } else {
      form.resetFields();
      if (companyId) {
        form.setFieldsValue({ company_id: companyId });
      }
    }
  }, [tagData, form, companyId]);

  const onFinish = async (values) => {
    try {
      if (!values.company_id) {
        notification.error({ message: "Please select or provide a company." });
        return;
      }

      if (tagData) {
        const updated = await updateTag(tagData.id, values);
        notification.success({ message: "Tag updated successfully." });
        if (handleAddOrUpdateTag) handleAddOrUpdateTag(updated);
      } else {
        const created = await createTag(values);
        notification.success({ message: "Tag created successfully." });
        if (handleAddOrUpdateTag) handleAddOrUpdateTag(created);
      }
      onClose();
    } catch (error) {
      notification.error({ message: "An error occurred while processing the tag." });
    }
  };

  return (
    <Drawer
      headerStyle={{ display: "none" }}
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      width={400}>
      <DrawerTitle height={118}>
        <CloseBtnWrapper onClick={onClose}>
          <CloseBtnIcon />
        </CloseBtnWrapper>
        <MainTitle>
          <p className="main-title">{tagData ? "Edit Tag" : "Add New Tag"}</p>
        </MainTitle>
      </DrawerTitle>
      <DrawerBodyPadding>
        <Form layout="vertical" form={form} onFinish={onFinish}>
          <Form.Item
            name="tag_name"
            label="Tag Name"
            rules={[
              { required: true, message: "Please enter tag name" },
              { max: 15, message: "Maximum 15 characters allowed" }
            ]}>
            <Input placeholder="Enter tag name" />
          </Form.Item>
          <Form.Item name="tag_description" label="Description">
            <TextArea placeholder="Enter tag description" rows={3} />
          </Form.Item>
          <Form.Item name="status" label="Status" rules={[{ required: true, message: "Please select status" }]}>
            <Select placeholder="Select status">
              <Select.Option value="active">Active</Select.Option>
              <Select.Option value="inactive">Inactive</Select.Option>
            </Select>
          </Form.Item>

          {companyId && !companyOptions && (
            <Form.Item name="company_id" hidden>
              <Input type="hidden" />
            </Form.Item>
          )}

          {!companyId && companyOptions && (
            <Form.Item
              name="company_id"
              label="Company"
              rules={[{ required: true, message: "Please select a company" }]}>
              <Select placeholder="Select company" allowClear>
                {companyOptions.map((company) => (
                  <Select.Option key={company.id} value={company.id}>
                    {company.company_name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}


          <Row gutter={16} justify="end">
            <Col>
              <Button onClick={onClose}>Cancel</Button>
            </Col>
            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
      </DrawerBodyPadding>
    </Drawer>
  );
};

export default AddTagDrawer;
