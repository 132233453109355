import { Switch, Tag } from "antd";
import { COMPANY } from "constants/Company";
import CouponTable from "./CouponTableRender";
import CreatePromo from "./CreatePromoTableRender";

const TableColumns = (
  setSelectedCompany,
  setIsModalOpen,
  dispatch,
  EditCompany,
  setLoading,
  drawerOpen,
  setDrawerOpen,
  setCompanyData,
  createNewCompany,
  companyData,
  fetchCompanies,
  toggleFetchCompanies,
  navigate
) => {
  return [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50
    },
    {
      title: "Coupon",
      dataIndex: "coupon",
      key: "coupon",
      width: 100,
      render: (item, record) => (
        <CouponTable
          record={record}
          item={item}
          set={setSelectedCompany}
          dispatch={dispatch}
          edit={EditCompany}
          setLoading={setLoading}
          setModal={setIsModalOpen}
          fetchCompanies={fetchCompanies}
          toggleFetchCompanies={toggleFetchCompanies}
        />
      )
    },
    {
      title: "Create Promotion",
      dataIndex: "create_promo_flag",
      key: "create_promo_flag",
      width: 150,
      render: (item, record) => (
        <CreatePromo
          record={record}
          item={item}
          setSelectedCompany={setSelectedCompany}
          dispatch={dispatch}
          EditCompany={EditCompany}
          setLoading={setLoading}
          fetchCompanies={fetchCompanies}
          toggleFetchCompanies={toggleFetchCompanies}
        />
      )
    },
    {
      title: "Maintain Sites",
      dataIndex: "maintain_sites_flag",
      key: "maintain_sites_flag",
      width: 150,
      onHeaderCell: () => ({
        style: {
          whiteSpace: "nowrap"
        }
      }),
      render: (text, record) => (
        <Switch
          checked={record.maintain_sites_flag}
          onChange={(checked) => {
            const updatedCompany = { ...record, maintain_sites_flag: checked };
            dispatch(EditCompany(updatedCompany, setLoading, null, null, fetchCompanies, toggleFetchCompanies));
          }}
        />
      )
    },
    {
      ...COMPANY.COMPANY_NAME_TABLE,
      render: (a, item) => (
        <span
          className="link"
          style={{ color: "#1890ff", cursor: "pointer" }}
          onClick={() => {
            setDrawerOpen({ ...drawerOpen, open: true, screen: "Edit" });
            setCompanyData({ ...companyData, data: item });
            createNewCompany(false);
          }}>
          {item?.company_name}
        </span>
      )
    },
    {
      title: "Xibo Folder ID",
      dataIndex: "xibo_folder_id",
      key: "xibo_folder_id"
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status"
    },
    {
      title: "Vistar Name",
      dataIndex: ["vistarenv", "vistar_name"],
      key: "vistar_name",
      render: (text, record) => record.vistarenv?.vistar_name || ""
    },
    {
      title: "Advertiser Name",
      dataIndex: ["vistar_advertiser", "name"],
      key: "name",
      render: (text, record) => record.vistar_advertiser?.name || ""
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags, record) => {
        return tags && tags.length > 0 ? (
          <span>
            {tags.map((tag) => (
              <Tag
                key={tag.id}
                color="green"
                style={{
                  cursor: "pointer",
                  marginBottom: "5px",
                  borderRadius: "20px"
                }}
                onClick={() => navigate(`/company-tag?company_id=${record.id}`)}>
                {tag.tag_name}
              </Tag>
            ))}
          </span>
        ) : (
          <span>No Tags</span>
        );
      }
    },
    {
      title: "Color Palette",
      dataIndex: "color_palette",
      key: "color_palette",
      render: (_, record) => {
        if (!record.color_palette) return null;
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              columnGap: "4px"
            }}>
            {JSON.parse(record.color_palette).map((color, index) => (
              <div key={index} style={{ background: color, height: "12px", width: "12px" }} />
            ))}
          </div>
        );
      }
    },
    {
      title: "Font Family",
      render: (_, record) => {
        if (!record.font_family) return null;
        return <div>{Array.isArray(record.font_family) ? record.font_family.join(", ") : record.font_family}</div>;
      }
    }
  ];
};

export default TableColumns;
